// ========================================================
// ON READY
// ========================================================
document.addEventListener('DOMContentLoaded', function() {

	$.parallaxify({
		positionProperty: 'transform',
		responsive: true,
		motionType: 'gaussian',
		useMouseMove: false,
		motionAngleX: 70,
		motionAngleY: 70,
		alphaFilter: 0.5,
		adjustBasePosition: true,
		alphaPosition: 0.9,
	});

}, false);

//analytics
(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){ (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o), m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m) })(window,document,'script','https://www.google-analytics.com/analytics.js','ga'); ga('create', 'UA-81217862-2', 'auto'); ga('send', 'pageview');

//angular 
var scotchApp = angular.module('scotchApp', ['ngRoute', 'ngAnimate']);

scotchApp.config(function($routeProvider) {
	$routeProvider

		// routes
		.when('/', {
			templateUrl : 'pages/intro.php',
			controller  : 'homeController'
		})

		.when('/startscherm', {
			templateUrl : 'pages/startscherm.php',
			controller  : 'homeController'
		})

		.when('/inlog', {
			templateUrl : 'pages/inlog.php',
			controller  : 'homeController'
		})

		.when('/geen-abonnee', {
			templateUrl : 'pages/geen-abonnee.php',
			controller  : 'homeController'
		})

		.when('/meedoen', {
			templateUrl : 'pages/meedoen.php',
			controller  : 'homeController'
		})

		.when('/bedankt', {
			templateUrl : 'pages/bedankt.php',
			controller  : 'homeController'
		})

		.when('/wat-kan-ik-winnen', {
			templateUrl : 'pages/wat-kan-ik-winnen.php',
			controller  : 'homeController'
		})

		.when('/actievoorwaarden', {
			templateUrl : 'pages/actievoorwaarden.php',
			controller  : 'homeController'
		})
});

scotchApp.controller('homeController', function($scope) {

	$scope.$on("$routeChangeSuccess", function () {
		if (location.hash === '#/') {
			new introTitleAnimation();
		} 

		if (location.hash === '#/geen-abonnee') {
			spawnDropdown('data/providers.json', '#providerDropdown');
		}

		if (location.hash === '#/meedoen') {
			spawnDropdown('data/providers.json', '#providerDropdownSimple');

			spawnDropdown('data/season.json', '#seasonDropdown');
		}
	})
});

//form redirect
var sendForm = function() {
	$('.form').submit(function(){
		$.post('', function() {
			window.location = '#/bedankt';
		});
		
		return false;
	});
	console.log('yuh')
}

//cookiecontroller
var closeCookie = function() {
	document.querySelector('.cookie-bar').classList.add('cookie-disable');
}

//ddslick
var spawnDropdown = function(json, id) {
	$.get(json).done(function(data) {

		$(id).ddslick({
		    data: data,
		    width: 300,
		    imagePosition:"left",
		    background: '#ffffff',
		    onSelected: function(selectedData){
		        if (location.hash === '#/geen-abonnee') {
		        	$('#providerLink').attr("href", selectedData['selectedData'].link)
		        	$('#providerPrice').html(selectedData['selectedData'].price)
		        }
		    }   
		});

	});
}

//animation intro
var introTitleAnimation = function() {
	
	introTitleTl = new TimelineMax({
		onComplete:function() {
			setTimeout(function() {
				window.location.hash = "/startscherm";				
			}, 2000);
		}
	});

	introTitleTl

		.timeScale(1.9)

		//intro 1
		.to("#intro1 .intro-title-animation", 0.5,
			{ opacity: 1, scale: 1.3, ease: Power4.EaseInOut}, '+=0.5')

		.to("#intro1 .intro-title-animation", 1,
			{ scale: 1.3, ease: Power4.EaseInOut}, '-=0.5')


		.to("#intro1 .intro-title-flare", 0.5,
			{ opacity: 1, ease: Power4.EaseInOut})

		.to("#intro1 .intro-title-flare", 2,
			{ right: '-100%', transformOrigin: '50% 50%', ease: Power4.EaseInOut}, '-=0.5')

		.to("#intro1 .intro-title-flare", 0.5,
			{ opacity: 0, ease: Power4.EaseInOut}, '-=1')


		.to("#intro1 .intro-title-animation", 1,
			{ opacity: 1, scale: 1, ease: Power4.EaseInOut}, '-=1')

		//intro 2
		.to("#intro2 .intro-title-animation", 0.5,
			{ opacity: 1, scale: 1.3, ease: Power4.EaseInOut}, '-=0.5')

		.to("#intro2 .intro-title-animation", 1,
			{ scale: 1.3, ease: Power4.EaseInOut}, '-=0.5')


		.to("#intro2 .intro-title-flare", 0.5,
			{ opacity: 1, ease: Power4.EaseInOut})

		.to("#intro2 .intro-title-flare", 2,
			{ right: '-100%', transformOrigin: '50% 50%', ease: Power4.EaseInOut}, '-=0.5')

		.to("#intro2 .intro-title-flare", 0.5,
			{ opacity: 0, ease: Power4.EaseInOut}, '-=1')


		.to("#intro2 .intro-title-animation", 1,
			{ opacity: 1, scale: 1, ease: Power4.EaseInOut}, '-=1')

		//intro 3
		.to("#intro3 .intro-title-animation", 0.5,
			{ opacity: 1, scale: 1.3, ease: Power4.EaseInOut}, '-=0.5')

		.to("#intro3 .intro-title-animation", 1,
			{ scale: 1.3, ease: Power4.EaseInOut}, '-=0.5')


		.to("#intro3 .intro-title-flare", 0.5,
			{ opacity: 1, ease: Power4.EaseInOut})

		.to("#intro3 .intro-title-flare", 2,
			{ right: '-100%', transformOrigin: '50% 50%', ease: Power4.EaseInOut}, '-=0.5')

		.to("#intro3 .intro-title-flare", 0.5,
			{ opacity: 0, ease: Power4.EaseInOut}, '-=1')


		.to("#intro3 .intro-title-animation", 1,
			{ opacity: 1, scale: 1, ease: Power4.EaseInOut}, '-=1')

		//intro 4
		.to("#intro4 .intro-title-animation", 0.5,
			{ opacity: 1, scale: 1.3, ease: Power4.EaseInOut}, '-=0.5')

		.to("#intro4 .intro-title-animation", 1,
			{ scale: 1.3, ease: Power4.EaseInOut}, '-=0.5')


		.to("#intro4 .intro-title-flare", 0.5,
			{ opacity: 1, ease: Power4.EaseInOut})

		.to("#intro4 .intro-title-flare", 2,
			{ right: '-100%', transformOrigin: '50% 50%', ease: Power4.EaseInOut}, '-=0.5')

		.to("#intro4 .intro-title-flare", 0.5,
			{ opacity: 0, ease: Power4.EaseInOut}, '-=1')


		.to("#intro4 .intro-title-animation", 1,
			{ opacity: 1, scale: 1, ease: Power4.EaseInOut}, '-=1')

		//Intro images
		.from("#introImage", 1,
			{ opacity: 0, y: '+=20', ease: Power4.EaseInOut})
}